import '../index.css';
import logoprincipal from '../assets/logos/LOGOBLANCO.png';
import logogris from '../assets/logos/logogris.png';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HiOutlineMenu } from 'react-icons/hi';

function Nav(props) {
  const [colorChange, setColorchange] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  const isMobile = window.innerWidth < 1280;

  window.addEventListener('scroll', changeNavbarColor);

  return (
    <div className=' pb-16 xl:pb-0'>
    <nav
      className={`bg-white  border-scolor fixed transition duration-300 z-50 w-screen text-xl xl:px-5 px-4 py-4 md:py-6 xl:py-8 ${
        colorChange
          ? ' bg-opacity-100 text-[#77777A]  border-b xl:border-b-0'
          : ' xl:bg-opacity-30 xl:text-white bg-opacity-100 text-[#77777A] border-b xl:border-b-0 '
      }`}
    >
      <ul className="xl:inline list-none">
        <li className="float-left sm:pl-9  ">
          <img
            src={colorChange || isMobile ? logogris : logoprincipal}
            className="transition duration-500"
            alt=""
          />
        </li>
      </ul>
      <ul className="float-right 2xl:pr-9 2xl:text-2xl text-xl xl:pr-6 uppercase ">
        <button
          onClick={() => setShowNav(!showNav)}
          className="px-5 text-4xl md:text-5xl align-middle xl:hidden akrobat"
        >
          <HiOutlineMenu />
        </button>
        <div className="hidden xl:block">
          <NavItem to="/">Inicio</NavItem>
          <NavItem to="/nosotros">Nosotros</NavItem>
          <NavItem to="/soluciones">Soluciones</NavItem>
          <NavItem to="/proyectos">Proyectos</NavItem>
          {/* <NavItem to="/tablecoins">Tablecoins</NavItem> */}
          <NavItem to="/galerias">Galerías</NavItem>
          <NavItem to="/contacto">Contactenos</NavItem>
        </div>
      </ul>
      {showNav && (
        <div className=" mt-16 md:mt-20 xl:hidden text-center">
          <ul className=' grid text-3xl sm:text-4xl uppercase'>
          <NavItem to="/" border={true}>Inicio</NavItem>
          <NavItem to="/nosotros" border={true} >Nosotros</NavItem>
          <NavItem to="/soluciones" border={true} >Soluciones</NavItem>
          <NavItem to="/proyectos" border={true} >Proyectos</NavItem>
          {/* <NavItem to="/tablecoins" border={true} >Tablecoins</NavItem> */}
          <NavItem to="/galerias" border={true} >Galerías</NavItem>
          <NavItem to="/contacto" border={true} >Contactenos</NavItem><div className='border-scolor border-t-2'></div>
          </ul>
        </div>
      )}
    </nav>
    </div>
  );
}

function NavItem(props) {
  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `px-5 py-3 akrobat ${
          isActive ? 'cursor-default text-pcolor' : 'hover:text-pcolor/90'
        }
        ${props.border && 'border-scolor border-t-2'}
        `
      }
    >{props.children}</NavLink>
  );
}

export default Nav;
