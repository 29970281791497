import Nav from "./Navbar"
import Footer from "./footer"
import Header from "./Header"
import Title from "./Title"
import portada2 from "../assets/recursos/portadahome2.jpg"
import biglogo from "../assets/logos/biglogo.png"
import grid1 from "../assets/recursos/nosotrosgrid1.jpg"
import grid2 from "../assets/recursos/nosotrosgrid2.jpg"
import "../index.css"

export default function NosotrosResponsive() {
  return (
    <div>
      <Nav />
      <Header
        image={portada2}
        padding=" py-[22rem]"
        modifyBg="bg-green-500 bg-blend-multiply bg-center"
      >
        NUESTRA HISTORIA
      </Header>
      <Parte1 />
      <Parte2 />
      <Parte3 />
      <Parte4 />
      <Footer />
    </div>
  )
}

function Parte1() {
  return (
    <div className="xl:py-32 py-20 align-middle object-center xl:text-center text-justify ">
      <img src={biglogo} alt="" className="xl:px-[25%] px-[6%]" />
      <p className=" 2xl:text-[3.5rem] lg:px-[13%] px-[6%] xl:text-5xl text-2xl md:text-4xl  pt-16 text-scolor font-semibold">
        Nuestro proyecto nace en el 2016 con el fin de reciclar plástico de una
        manera sostenible, en donde se pretende desarrollar soluciones de alto
        valor agregado, que contribuyen de la sociedad y apuestan a la
        preservación de nuestro medio ambiente.
      </p>
    </div>
  )
}

function Parte2() {
  const isMobile = window.innerWidth < 1280
  if (isMobile) {
    return (
      <div>
        <div className=" bg-pcolor pb-10">
          <div className="block videobg w-full">
            <div className="relative pt-[56.25%] mx-[12%] ">
              <iframe
                className="absolute inset-0 w-full h-[100%] "
                src="https://www.youtube.com/embed/YzD84IDI1XA"
                frameBorder="0"
                title="tableco video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className=" pt-10 text-2xl text-center text-scolor px-[5%] ">
            <h1 className=" font-bold  md:text-3xl text-2xl  tracking-wider">
              AL COMPRAR UNA TABLA O UNA SOLUCIÓN TABLECO
            </h1>
            <p className=" pt-5 text-xl">
              Usted está colaborando directamente al desarrollo de un proyecto
              sostenible e innovador, que previene la tala de árboles, reduce la
              contaminación de océanos, genera trabajo y concientiza a la
              población en Nicaragua.
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className=" pb-40">
      <div className=" relative pt-[56.25%] xl:mx-80">
        <iframe
          className="absolute inset-0 w-full h-[80%]"
          src="https://www.youtube.com/embed/YzD84IDI1XA"
          frameBorder="0"
          title="tableco video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="2xl:mx-48 2xl:-mt-[40rem] 2xl:-pt-[40rem] xl:mx-40 xl:-mt-[37rem] xl:-pt-[37rem] bg-pcolor pb-40">
        <div className=" text-4xl pt-[32rem] mx-36 text-center text-scolor ">
          <h1 className="2xl:text-6xl  text-5xl leading-[4rem] font-bold tracking-wider xl:tracking-widest">
            AL COMPRAR UNA TABLA <br />O UNA SOLUCIÓN TABLECO
          </h1>
          <p className=" text-4xl mt-14 tracking-wide">
            Usted está colaborando directamente al desarrollo de un proyecto
            sostenible e innovador, que previene la tala de árboles, reduce la
            contaminación de océanos, genera trabajo y concientiza a la
            población en Nicaragua.
          </p>
        </div>
      </div>
    </div>
  )
}

function Parte3() {
  return (
    <div className="xl:-mt-28">
      <Title description="de trabajo duro">UN PROYECTO</Title>
      <div className="grid xl:grid-cols-2 pb-16 overflow-hidden text-xl md:text-2xl">
        <div>
          <p className="m-4 xl:m-10  2xl:leading-[4rem] 2xl:text-5xl xl:text-4xl  xl:leading-10 text-scolor text-justify">
            El proyecto requirió de un periodo de aprendizaje muy intenso en el
            desarrollo de la tecnología, la fabricación de moldes in situ, la
            mezcla y procesamiento de los distintos plásticos que hemos logrado
            procesar de manera exitosa en la fábrica.
            <br />
            <br />
            Todo este proceso nos tomó casi un año y posteriormente se dio el
            inicio a la promoción y divulgación de nuestro concepto.
          </p>
        </div>
        <div>
          <img src={grid1} className=" my-5  xl:ml-10 " alt="" />
        </div>
        <div>
          <img src={grid2} className=" my-5 xl:pr-10" alt="" />
        </div>
        <div>
          <p className="m-10  2xl:leading-[4rem] 2xl:text-5xl xl:text-4xl xl:leading-10 text-scolor text-justify">
            En todo este transcurso hemos adquirido una experiencia enorme en el
            desarrollo de soluciones y cada día logramos mayor sofisticación en
            nuestros productos y diseños.
            <br />
            <br />
            Todo esto con el ánimo de exponenciar nuestro concepto y colaborar
            de manera sustentable a nuestra madre naturaleza.
          </p>
        </div>
      </div>
    </div>
  )
}

function Parte4() {
  return (
    <div
      className="grid xl:grid-cols-2 xl:p-32 pb-20 p-4 sm:p-8 md:p-12 tracking-wide bg-[#f3e4b2] font-semibold
     text-scolor text-justify xl:text-4xl text-xl sm:text-2xl xl:leading-[3rem] xl:gap-x-20 gap-y-12"
    >
      <div>
        <h1 className=" text-center font-bold xl:text-6xl text-3xl tracking-widest xl:pb-10 pb-4">
          NUESTRA MISIÓN
        </h1>
        <p>
          Gestionar y dar un tratamiento óptimo de residuos plásticos de manera
          sostenible, transformando desechos plásticos en tablas ecológicas e
          innovadoras que generen valor, contribuyendo a la prevención de tala
          de árboles y generación de empleos en la región.
        </p>
      </div>
      <div>
        <h1 className=" text-center font-bold xl:text-6xl text-3xl tracking-widest xl:pb-10 pb-4">
          NUESTRA VISIÓN
        </h1>
        <p>
          Innovar y potencializar la transformación de residuos plásticos en
          productos de alto valor comercial con calidad de exportación. Ser el
          socio principal de organizaciones e instituciones en la gestión de sus
          residuos plásticos, convirtiéndonos en su mejor aliado para el
          desarrollo e implementación de sus programas de RSE y buena imagen
          Corporativa.
        </p>
      </div>
      <div className=" xl:col-span-2 border-t-4 border-scolor xl:mt-12 mt-4">
        <h1 className=" text-center font-bold xl:text-6xl text-4xl tracking-widest xl:py-16 py-5">
          NUESTROS VALORES
        </h1>
      </div>
      <div>
        <h1 className="font-bold text-[125%]">INNOVACIÓN:</h1>
        <p>
          Innovamos constante nuestra línea de negocio, para agregarle un alto
          valor a nuestros productos.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-[125%]">EFICIENCIA:</h1>
        <p>
          Somos eficiente en el uso de los recursos, ahorro energía y
          materiales.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-[125%] xl:pt-16">COMPROMISO:</h1>
        <p>
          Nuestro cliente tiene primer lugar, procuramos superar las
          expectativas de nuestros clientes y asociados.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-[125%] xl:pt-16">RESPONSABILIDAD:</h1>
        <p>Con la madre tierra y generaciones venideras.</p>
      </div>
    </div>
  )
}
