export default function Header(props) {
  const isMobile = window.innerWidth <= 900;
  return (
    <div
      style={{ backgroundImage: `url(${props.image})` }}
      className={` ${props.modifyBg}`}
    >
      <div
        className={` align-middle block xl:w-full text-white text-center ${isMobile ? 'py-40': props.padding}`}
      >
        <h1
          className={`py-5 2xl:mx-96 border-2 xl:mx-48 mx-12 font-bold tracking-widest
          ${props.borderscolor ? 'border-scolor': 'border-white'} ${
            props.small
              ? " text-xl xl:text-3xl 2xl:text-4xl"
              : props.medium
              ? "text-6xl 2xl:text-7xl"
              : "xl:text-7xl 2xl:text-8xl sm:text-5xl text-4xl"
          }  `}
        >
          {props.useDesc ? props.desc : props.children}
        </h1>

        <p className="xl:text-5xl 2xl:mx-96 xl:mx-48 drop-shadow-xl pt-5 px-6 text-2xl">
          {!props.useDesc && props.desc}
        </p>
      </div>
    </div>
  )
}
