import "../index.css"
import Nav from "./Navbar"
import Footer from "./footer"
import Header from "./Header"
import Title from "./Title"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination, Autoplay, EffectCoverflow, FreeMode } from "swiper"
import headerg from "../assets/recursos/headerg.jpg"
import slide1 from "../assets/recursos/slide-1-1200x860.jpg"
import slide2 from "../assets/recursos/slide-3-1200x860.jpg"
import slide3 from "../assets/recursos/slide-5-1200x860.jpg"

const placeholderlist = [headerg, slide1, slide2, slide3]

export default function Galerias() {
  return (
    <div className=" akrobat">
      <Nav />
      <Header
        image={headerg}
        padding=" py-[22rem]"
        modifyBg="bg-stone-900/50 bg-blend-multiply"
      >
        GALERÍA DE PROYECTOS
      </Header>
      <Title description="para ayudar al medio ambiente">
        CREAMOS ALGO DIFERENTE
      </Title>
      <TopSwiper />
      <TextSeparator />
      <TwoRowSwiper />
      <Footer />
    </div>
  )
}

function TopSwiper() {
  let galist = [...placeholderlist]
  //Para meter mas imagenes solo agregar al galist

  return (
    <div className=" xl:px-20 px-5 xl:pt-16 pt-2">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        pagination={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 80,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 4000,
        }}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        {galist.map((slide) => (
          <SwiperSlide>
            <img src={slide} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

function TextSeparator() {
  return (
    <p className="text-center 2xl:pt-40 xl:pt-28 2xl:text-5xl xl:text-4xl text-2xl xl:px-28 px-5 py-10 text-sc">
      Queremos seguir innovando para embellecer sus espacios de sala de espera,
      terrazas, jardines, escuelas y todo lugar donde podamos crear conciencia
      de la importancia del reciclaje y las ventajes que obtenemos.
    </p>
  )
}

function TwoRowSwiper() {
  let firstlist = [...placeholderlist] //Lo mismo, solo se tienen que agregar acá
  let secondlist = [...placeholderlist].reverse()

  const generateRow = (lista) => {
    return (
      <div>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            800: {
              slidesPerView: 3,
              spaceBetween:30,
            },
          }}
          className=""
          freeMode={true}
          grabCursor={true}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          speed={10000}
          modules={[FreeMode, Autoplay]}
          loop={true}
        >
          {lista.map((img) => (
            <SwiperSlide>
              <img src={img} className="" alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }

  return (
    <div className=" 2xl:py-40 xl:py-28 pb-10">
      {generateRow(firstlist)}
      <div className="mt-[30px]"></div>
      {generateRow(secondlist)}
    </div>
  )
}
