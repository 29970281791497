import "../index.css"
import Nav from "./Navbar"
import Footer from "./footer"
import Header from "./Header"
import Title from "./Title"
import headerp from "../assets/recursos/headerp.jpg"
import g1 from "../assets/recursos/g1.jpg"
import g2 from "../assets/recursos/g2.jpg"
import g3 from "../assets/recursos/g3.jpg"
import g4 from "../assets/recursos/g4.jpg"
import pbasura from "../assets/dibujitos/pbasura.png"
import pmesa from "../assets/dibujitos/pmesa.png"
import pjuego from "../assets/dibujitos/pjuego.png"
import psilla from "../assets/dibujitos/psilla.png"

export default function Proyectos() {
  return (
    <div className=" akrobat text-scolor">
      <Nav />
      <Header
        image={headerp}
        padding=" py-[22rem]"
        modifyBg="bg-stone-900/50 bg-blend-multiply"
      >
        NUESTROS PROYECTOS
      </Header>
      <Parte1 />
      <Parte2 />
      <Parte3 />
      <Parte3Grid />
      <div className=" pt-8"></div>
      <Title description="del proyecto">VENTAJAS Y DETALLE</Title>
      <Parte4 />
      <Footer />
    </div>
  )
}

function Parte1() {
  return (
    <div className=" pb-20 xl:mx-[6%] border-b-2 border-scolor">
      <Title
        description={
          <div className=" text-[110%] font-semibold">
            positivo de imagen corporativa <br />
            de nuestros clientes
          </div>
        }
      >
        GENERACIÓN DE IMPACTO
      </Title>
      <p className=" text-center 2xl:text-[2.6rem] xl:text-5xl xl:mx-[15%] pt-6 text-3xl">
        Utilizamos sus residuos plásticos y los convertimos en productos para
        promocionar su compromiso con el Medio Ambiente
      </p>
    </div>
  )
}

function Parte2() {
  return (
    <div className=" pb-16 xl:pb-32">
      <Title description="se parte del cambio">NUESTRAS SOLUCIONES</Title>
      <div className="grid mx-[10%] xl:grid-cols-4 md:grid-cols-2 text-center grid-cols-1 text-bold 2xl:text-5xl text-4xl font-bold tracking-wider pb-14 xl:pb-28">
        <div className=" mx-auto">
          <img src={psilla} alt="" />
          <p className="py-10 ">BANCAS</p>
        </div>
        <div className=" mx-auto">
          <img src={pbasura} alt="" />
          <p className="py-10 ">BASUREROS</p>
        </div>
        <div className=" mx-auto">
          <img src={pmesa} alt="" />
          <p className="py-10 "> MESA PICNIC</p>
        </div>
        <div className=" mx-auto">
          <img src={pjuego} alt="" />
          <p className="py-10 ">COLUMPIOS</p>
        </div>
      </div>
      <div className="xl:mx-[10%] mx-[5%] text-center 2xl:text-5xl xl:text-4xl text-3xl font-semibold tracking-widez">
        <p className=" ">
          Tambien contamos con pasarelas, cambiadores de playa, juegos de niños
        </p>
        <p className="pt-[1%]">
          playeros estos ultimos puedes personalizarlos con tu logotipo.
        </p>
      </div>
    </div>
  )
}

function Parte3() {
  return (
    <div>
      <Header
        padding=" py-[10rem]"
        modifyBg="bg-[#f3e4b2] "
        borderscolor={true}
      >
        {<div className=" text-scolor font-bold ">RECICLAR PARA EDUCAR</div>}
      </Header>
      <div className=" xl:px-[12%] px-[7%] xl:text-center text-justify bg-[#f3e4b2] -mt-12 xl:pb-24 pb-10">
        <p className=" font-semibold 2xl:text-7xl xl:text-6xl text-3xl">
          Proyectos de Interés social en alianza con empresas, ONG e
          instituciones
        </p>{" "}
        <br /> <br /> <br />
        <p className=" 2xl:text-[3.40rem] xl:text-[2.5rem]  text-2xl xl:leading-[4rem] ">
          Canje de residuos plásticos por soluciones con alto valor agregado.
          <br />
          Los Colegios y Universidades canjean todos sus plásticos y latas a
          cambio de nuestro mobiliario urbano, incluyendo pupitres
        </p>
      </div>
    </div>
  )
}

function Parte3Grid() {
  return (
    <div>
      <div className="grid xl:grid-cols-2 bg-[#f3e4b2] py-12 pb-24 2xl:gap-16 xl:gap-14">
        <div className="2xl:ml-28 xl:ml-18  border-[#dac386] xl:border-[12px] border-4">
          <img src={g1} alt="" className="w-full  h-full xl:max-h-[679px] " />
        </div>
        <div className="border-[#dac386]  xl:border-[12px] border-4 border-r-0">
          <img src={g2} alt="" className="w-full h-full " />
        </div>
        <div className="border-[#dac386]   xl:border-[12px] border-4 border-l-0">
          <img src={g3} alt="" className="w-full h-full " />
        </div>
        <div className="2xl:mr-28 xl:mr-18 border-[#dac386]  xl:border-[12px] border-4">
          <img src={g4} alt="" className="w-full h-full max-h-[679px] " />
        </div>
      </div>
    </div>
  )
}

function Parte4() {
  return (
    <div>
      <ListRow n="1">
        Nicaragua, primer país en el mundo en convertir el plástico en pupitres
        para el desarrollo sostenible y un futuro brillante educativo para los
        niños y jóvenes.
      </ListRow>
      <ListRow n="2">
        Con este proyecto ayudamos a limpiar gratuitamente 129,600 kg de basura
        | año.
      </ListRow>
      <ListRow n="3">
        Nuestro proyecto de cambio genera más de 30 puestos de trabajo directo y
        150 puestos indirectos en reciclaje.
      </ListRow>
      <ListRow n="4">
        Este es un proyecto que convierte la basura plástica en un producto
        duradero y útil a la sociedad.
      </ListRow>
      <ListRow n="5">
        Reciclar más plásticos y reducir contaminación ambiental.
      </ListRow>
      <ListRow n="6">
        Comunidades limpias de contaminación de residuos como el plástico, el
        cartón y el aluminio.
      </ListRow>
    </div>
  )
}
function ListRow(props) {
  return (
    <div className="grid grid-cols-4 xl:pb-12 pb-5 pt-5">
      <div
        className=" text-white  xl:text-[10rem] text-8xl bg-pcolor font-extrabold"
        style={{ textShadow: "-2.5px 1.5px rgb(0,0,0, 0.3)" }}
      >
        <div className="xl:ml-[70%] text-center xl:text-left xl:py-6">{props.n}</div>
      </div>
      <div className=" col-span-3 2xl:text-6xl xl:text-[2.5rem] md:text-2xl 2xl:ml-24 xl:ml-16 ml-3 font-semibold 2xl:mr-10">
        {props.children}
      </div>
    </div>
  )
}
