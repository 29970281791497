import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import Inicio from "./components/Inicio"
import Nosotros from "./components/Nosotros"
import Soluciones from "./components/Soluciones"
import Proyectos from "./components/Proyectos"
import Galerias from "./components/Galerias"
import Contacto from "./components/Contacto"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import ScrollTop from "./components/ScrollTop"

function Index() {
  return (
    <main className="">
      <Routes>
        <Route exact path="/" element={<Inicio />} />
        <Route exact path="/nosotros" element={<Nosotros />} />
        <Route exact path="/soluciones" element={<Soluciones />} />
        <Route exact path="/proyectos" element={<Proyectos />} />
        <Route exact path="/galerias" element={<Galerias />} />
        <Route exact path="/contacto" element={<Contacto />} />
      </Routes>
    </main>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <ScrollTop />
    <Index />
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
