import "../index.css"
import Nav from "./Navbar"
import Footer from "./footer"
import Header from "./Header"
import Title from "./Title"
import headerc from "../assets/recursos/headerc.jpg"

export default function Contacto() {
  return (
    <div className="akrobat">
      <Nav />
      <Header
        image={headerc}
        padding=" py-[22rem]"
        modifyBg="bg-stone-900/50 bg-blend-multiply"
      >
        NUESTROS CONTACTOS
      </Header>
      <Title description="con gusto nos comunicaremos con usted">
        CONTACTANOS
      </Title>
      <FormularioContacto />
      <ParteMapa />
      <Footer />
    </div>
  )
}

function FormularioContacto() {
  //TODO: Hay que hacer una funcion que envie los datos a la API

  return (
    <div className="pt-10 xl:mx-[15%] mx-[8%] xl:pb-32 pb-5 ">
      <h1 className=" text-center font-bold xl:pb-20 xl:text-7xl text-3xl text-scolor ">
        Llená el formulario
      </h1>
      <form action="" className="2xl:text-6xl xl:text-5xl">
        <input
          type="text"
          name=""
          id="f1"
          placeholder="Nombre"
          className="pt-10 xl:border-b-2  border-b pb-2 border-scolor w-full  focus:outline-none"
        />
        <br />
        <input
          type="email"
          name="correo"
          id="f2"
          placeholder="Correo"
          className="xl:pt-10 pt-5 xl:border-b-2 border-b pb-2 border-scolor w-full focus:outline-none"
        />
        <br />
        <input
          type="tel"
          name="telefono"
          id="f3"
          placeholder="Teléfono"
          className="xl:pt-10 pt-5 xl:border-b-2  border-b pb-2 border-scolor w-full focus:outline-none"
        />
        <br />
        <input
          type="text"
          id="f4"
          placeholder="Mensaje"
          className="xl:pt-10 pt-5  xl:border-b-2  border-b pb-2 border-scolor w-full focus:outline-none"
        />
        <br />
        <br />
        <input
          type="button"
          value="ENVIAR"
          className=" text-center font-bold tracking-wider block mx-auto xl:py-6 py-2 xl:px-10 px-5 xl:mt-10 mt-4
          2xl:font-8xl text-white bg-pcolor transition duration-300 cursor-pointer active:bg-yellow-100 "
        />
      </form>
    </div>
  )
}

function ParteMapa() {
  return (
    <div className="xl:pb-32 pb-10 pt-6">
      <div className="">
      <div className="overflow-hidden pb-[56.25%] relative h-0 xl:mx-[17%] mx-[5%]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5516.108394183511!2d-86.23720344957398!3d12.147849909245242!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xce022224392d177b!2sTABLECO!5e0!3m2!1ses-419!2sni!4v1645398428609!5m2!1ses-419!2sni"
            width="1000"
            height="650"
            allowFullScreen=""
            title="Video de ejemplo"
            loading="lazy"
            className=" h-[68%] w-full absolute"
          ></iframe>
        </div>
        <div className="bg-[#f3e4b2]  mx-[3%] xl:mx-[7%]  mt-[-38%] xl:pb-32 pb-6">
          <div className=" pt-[30%] 2xl:text-4xl xl:text-3xl text-xl text-scolor text-center ">
            <h1 className="font-bold text-[150%] tracking-wide">Dirección</h1>{" "}
            <p className="xl:pt-8 xl:mx-[25%]">
              Carretera norte, semáforos de la Robelo 200 metros al sur, 100
              metros al este.
              Managua, Nicaragua.
            </p>
            <h1 className="font-bold text-[150%] tracking-wide pt-3 xl:pt-16">
              Horario de atención
            </h1>
            <p className="mx-8 xl:mx-0 xl:pt-8">Lunes - Viernes: 8:00 - 17:00 y Sábado: 8:00 - 12:00</p>
          </div>
        </div>
      </div>
    </div>
  )
}
