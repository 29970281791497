import '../index.css'
import smile from '../assets/logos/smilelogo.png'
import {FaFacebookF, FaTwitter, FaGooglePlusG, FaLinkedinIn} from 'react-icons/fa'


function Footer(){
    const scolor = '#77777A'

    return (
        <div className=''>
            <div className='grid xl:grid-cols-3 main-font text-xl md:text-2xl' style={{backgroundColor: scolor}}>
                <div className=''>
                    <img src={smile} alt="" className=' pt-8 md:pt-16 xl:pt-0 mx-auto my-auto xl:my-20'/>
                </div>
                <div className='p-4 ml-4'><h2 className='primary-color font-bold'>Contáctenos</h2>
                    <ul className=' text-white font-bold 2xl:mr-64 lg:mr-32 md:mr-24 sm:mr-16'>
                        <li >
                            (+505) 2250-6368
                        </li>
                        <li>
                            (+505) 8776-6560
                        </li>
                        <li>
                            (+505) 8881-0015
                        </li>
                            <br />
                        <li>
                            info@tablecoplastic.com
                        </li>
                    </ul>
                </div>
                <div className='p-4 ml-4 font-bold 2xl:mr-64 xl:mr-36 md:mr-24 sm:mr-16'>
                    <h2 className='primary-color '>Dirección</h2>
                    <p className=' text-white font-semibold'>
                        Carretera norte, semáforos
                        de la Robelo 200 metros al sur,
                        100 metros al este. Managua, Nicaragua.
                    </p>
                    <br />
                    <p className='text-white text-lg font-semibold'>© 2019 Tableco. Todos los Derechos Reservados.</p>
                </div>
            </div>
            <div className=' bg-[#c4c4c6] block'>
                <div className='text-white text-center font-bold   py-3 text-4xl '>
                    <a href="#"><FaFacebookF className='inline mr-12 text-4xl transition hover:text-blue-300'/></a>
                    <a href="#"><FaGooglePlusG className='inline  mr-12  text-5xl transition hover:text-red-400'/></a>
                    <a href="#"><FaTwitter className='inline  mr-12 transition hover:text-sky-300'/></a>
                    <a href="#"><FaLinkedinIn className='inline transition hover:text-blue-500'/></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;