import "../index.css"
import Nav from "./Navbar"
import portada1 from "../assets/recursos/pantalla1.jpg"
import portada2 from "../assets/recursos/portadahome2.jpg"
import portada3 from "../assets/recursos/portadahome3.jpg"
import portada4 from "../assets/recursos/portadahome4.jpg"
import Footer from "./footer"
import bazul from "../assets/recursos/bazul.jpg"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination, Autoplay } from "swiper"
import "../assets/fonts/fonts.css"
import Title from "./Title"
import diagrama from "../assets/dibujitos/diagrama.png"
import Header from "./Header"
import hoja from "../assets/dibujitos/iniciohoja.png"
import madera from "../assets/dibujitos/iniciomadera.png"
import reciclar from "../assets/dibujitos/inicioreciclar.png"
import basura from "../assets/dibujitos/mainbasura.png"
import desechos from "../assets/dibujitos/maindesechos.png"
import sierra from "../assets/dibujitos/mainsierra.png"
import temp from "../assets/dibujitos/maintemp.png"

function MainCarrousel() {
  return (
    <div className="">
      <Swiper
        pagination={true}
        loop={true}
        grabCursor={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="bg-white w-full lg:max-h-[52rem] md:max-h-[36rem] sm:max-h-[24rem] text-center"
      >
        <SwiperSlide>
          <img src={portada1} alt=""  />
        </SwiperSlide>
        <SwiperSlide>
          <img src={portada2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={portada3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={portada4} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

function Inicio() {
  return (
    <div className=" m-0 p-0 relative akrobat">
      <Nav />
      <div className="w-full">
        <MainCarrousel />
      </div>
      <Parte1Responsive />
      <Parte2 />
      <Parte3 />
      <Header
        padding="py-40"
        modifyBg="bg-stone-700/50 bg-blend-multiply bg-center"
        image={portada2}
        desc="Mobiliario urbano hecho a la medida,
                fabricado con desechos 
                plásticos PEAD, Polipropileno, PE"
      >
        SOLUCIONES URBANAS
      </Header>
      <Footer />
    </div>
  )
}

function Parte1Responsive() {
  return (
    <div className=" lg:pb-20">
      <Title description="hechas a la medida">SOLUCIONES URBANAS</Title>
      <div className="grid xl:grid-cols-3 grid-cols-1 md:grid-cols-2  text-scolor text-xl lg:text-2xl 2xl:text-3xl text-center lg:pt-10">
        <div className=" pt-10 md:pt-16 xl:pt-0">
          <img
            src={reciclar}
            alt=""
            className="md:mx-auto px-[25%] md:px-[10%] xl:px-0 xl:pb-16 pb-8"
          />
          <h2 className=" font-bold">LÍNEA DE RECICLADO</h2>
          <p className=" xl:pt-5">Capacidad instalada 250 TM/mes</p>
        </div>
        <div className=" pt-16 xl:pt-0">
          <img
            src={hoja}
            alt=""
            className=" md:mx-auto px-[25%] md:px-[10%] xl:px-0 xl:pb-16 pb-8"
          />
          <h2 className=" font-bold">SERVICIOS SOSTENIBLE</h2>
          <ul className=" xl:pt-5">
            <li>- Selección de materia prima</li>
            <li>- Peletizado de desechos plástico</li>
            <li>- Triturado y lavado de PET para su</li>
            <li>exportación para hacer hilos y RPET</li>
          </ul>
        </div>
        <div className=" pt-16 xl:pt-0">
          <img
            src={madera}
            alt=""
            className=" md:mx-auto px-[25%] md:px-[10%] xl:px-0 xl:pb-16 pb-8"
          />
          <h2 className=" font-bold">MADERA PLÁSTICA</h2>
          <p className=" xl:pt-5">Capacidad instalada 80 TM/mes</p>
        </div>
      </div>
    </div>
  )
}

function Parte2() {
  return (
    <div className="grid xl:grid-cols-2 grid-cols-1 w-full mt-10 xl:mt-0">
      <div className=" bg-[#f3e4b2] text-scolor lg:p-20 md:p-10 p-7">
        <h1 className=" xl:text-6xl 2xl:text-8xl text-4xl md:text-6xl text-scolor font-bold tracking-wider">
          TRANSFORMACIÓN
        </h1>
        <h3 className=" xl:text-6xl text-3xl md:text-5xl font-semibold tracking-wide">
          de madera plástica
        </h3>
        <ul className=" xl:text-5xl text-2xl md:text-4xl xl:pt-5">
          <li className="xl:pt-5 pt-2">- Soluciones Urbanas</li>
          <li className="xl:pt-5 pt-2">- Soluciones Viales</li>
          <li className="xl:pt-5 pt-2">- Soluciones Agroindustriales</li>
          <li className="xl:pt-5 pt-2">- Soluciones Escolares</li>
          <li className="xl:pt-5 pt-2">- Soluciones a la medida</li>
        </ul>
      </div>
      <div className="">
        <img src={bazul} alt="" className=" h-full w-auto" />
      </div>
    </div>
  )
}

function Parte3() {
  const isMobile = window.innerWidth <= 900
  if (isMobile) {
    return (
      <div className="pt-2 pb-14">
        <Title description="ciclo de reciclaje">MADERA PLÁSTICA</Title>
        <div className=" border-scolor border-b-2 mx-[7%]"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 pt-5">

          <div className="text-center md:pt-8">
            <img
              src={basura}
              alt=""
              className=" mx-auto  pb-8"
            />
            <h2 className="text-4xl font-bold text-pcolor tracking-wide">
              DESECHOS
            </h2>
            <p className=" text-scolor text-2xl mx-5 pt-2">
              Desechos plásticos son clasificados por tipo de plástico y color
            </p>
          </div>

          <div className="text-center pt-8 ">
            <img
              src={desechos}
              alt=""
              className=" mx-auto  pb-8"
            />
            <h2 className="text-4xl font-bold text-pcolor tracking-wide">
              TRATAMIENTO
            </h2>
            <p className=" text-scolor text-2xl mx-5 pt-2">
              El plástico es tratado bajo ciclo de triturado, lavado en frío y
              secado
            </p>
          </div>

          <div className="text-center pt-12 ">
            <img
              src={temp}
              alt=""
              className=" mx-auto  pb-8"
            />
            <h2 className="text-4xl font-bold text-pcolor tracking-wide">
              TRANSFORMACIÓN
            </h2>
            <p className=" text-scolor text-2xl mx-5 pt-2">
            El plástico es sometido a altas temperaturas, logrando transformarlo
            en diferentes
            </p>
          </div>

          <div className="text-center pt-12 ">
            <img
              src={sierra}
              alt=""
              className=" mx-auto  pb-8"
            />
            <h2 className="text-4xl font-bold text-pcolor tracking-wide">
              SOLUCIONES
            </h2>
            <p className=" text-scolor text-2xl mx-5 pt-2">
            Los perfiles son enviados al taller de carpintería, ahí se fabrican
            y construyen soluciones
            </p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className=" pb-24 overflow-hidden">
      <Title description="- ciclo de reciclaje -">MADERA PLÁSTICA</Title>
      <img src={diagrama} alt="" className="lg:px-28 2xl:ml-5" />
      <div className="grid grid-cols-4 2xl:text-3xl xl:text-2xl text-xl 2xl:mx-[-2.5%] xl:mx-[-1%]  gap-5">
        <div className="text-center pt-7 ml-10 2xl:ml-16">
          <h2 className="text-4xl font-bold text-pcolor mx-auto 2xl:mr-6">
            DESECHOS
          </h2>
          <br />
          <p className=" text-scolor">
            Desechos plásticos son clasificados por tipo de plástico y color
          </p>
        </div>
        <div className="text-center pt-7 mx-auto">
          <h2 className="text-4xl font-bold text-pcolor">TRATAMIENTO</h2>
          <br />
          <p className=" text-scolor">
            El plástico es tratado bajo ciclo de triturado, lavado en frío y
            secado
          </p>
        </div>
        <div className="text-center pt-7 mx-auto">
          <h2 className="text-4xl font-bold text-pcolor xl:mr-10 2xl:mr-0">
            TRANSFORMACIÓN
          </h2>
          <br />
          <p className=" text-scolor">
            El plástico es sometido a altas temperaturas, logrando transformarlo
            en diferentes
          </p>
        </div>
        <div className="text-center pt-7 mx-auto mr-10">
          <h2 className="text-4xl font-bold text-pcolor ">SOLUCIONES</h2>
          <br />
          <p className=" text-scolor">
            Los perfiles son enviados al taller de carpintería, ahí se fabrican
            y construyen soluciones
          </p>
        </div>
      </div>
    </div>
  )
}

export default Inicio
