import Nav from "./Navbar";
import Footer from "./footer";
import Header from "./Header";
import Title from "./Title";
import muebles from "../assets/recursos/solucionesmuebles.jpg";
import headers from "../assets/recursos/headersoluciones.jpg";
import solucionesdesc from "../assets/recursos/solucionesdesc.jpg";

export default function Soluciones() {
  return (
    <div className="block akrobat">
      <Nav />
      <Header
        image={headers}
        padding=" py-[22rem]"
        modifyBg="bg-stone-900/50 bg-blend-multiply bg-center"
      >
        NUESTRAS SOLUCIONES
      </Header>
      <Parte1 />
      <Header
        image={solucionesdesc}
        padding=" py-[11rem]"
        small={true}
        useDesc={true}
        desc="Mobiliario urbano hecho a la medida, fabricado con desechos plásticos 
                PEAD, Polipropileno, PE
                Utilizamos moldes específicos, inyectados bajo 
                estrictas normas de calidad, garantizando 
                resistencia y durabilidad."
        modifyBg="bg-green-500/95 xl:bg-blend-multiply bg-center"
      />
      <Footer />
    </div>
  );
}

function Parte1() {
  return (
    <div>
      <Title description="hechas a la medida">SOLUCIONES URBANAS</Title>
      <p className=" text-center text-scolor xl:text-5xl md:text-4 xl mx-[10%]  text-2xl">
        Mobiliario urbano hecho a la medida, fabricado con desechos plásticos
        PEAD, Polipropileno, PE
      </p>
      <div className="grid xl:grid-cols-2 p-10 gap-14  py-24">
        <div>
          <img src={muebles} alt="" />
        </div>
        <div>
          <p className=" text-scolor 2xl:text-6xl text-3xl xl:text-5xl py-15 xl:leading-[4.5rem]">
            - Bancas <br />
            - Basureros <br />
            - Mesa picnic <br />
            - Pasarelas* <br />
            - Cambiadores de playa* <br />
            - Columpios de playa* <br />
            - Juegos de niños playeros* <br />
            <br />
            <br />
            *Mobiliario que podés <br />
            personalizar con tu logotipo
          </p>
        </div>
      </div>
    </div>
  );
}
